import '../../styles/home/Prices.scss';
import { useTranslation } from 'react-i18next';
import ToolIcon1 from '../../assets/icons/price1.png';
import ToolIcon2 from '../../assets/icons/price2.png';
import ToolIcon3 from '../../assets/icons/price3.png';
import ToolIcon4 from '../../assets/icons/price4.png';
import ToolIcon5 from '../../assets/icons/price5.png';
import ToolIcon6 from '../../assets/icons/price6.png';


function Prices() {

    // i18next 

    const { t } = useTranslation();

    return (
        <div className="Prices parent" id='implementation'>
            <div className="wrapper">
                <div className="left gap-2-5">
                    <p className="name">{t("imp")}</p>
                    <div className="tools gap-2-5">
                        <div className="tool gap-2-5">
                            <img src={ToolIcon1} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt1")}</p>
                        </div>
                        <div className="tool gap-2-5">
                            <img src={ToolIcon2} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt2")}</p>
                        </div>
                        <div className="tool gap-2-5">
                            <img src={ToolIcon3} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt3")}</p>
                        </div>
                        <div className="tool gap-2-5">
                            <img src={ToolIcon4} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt4")}</p>
                        </div>
                        <div className="tool gap-2-5">
                            <img src={ToolIcon5} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt5")}</p>
                        </div>
                        <div className="tool gap-2-5">
                            <img src={ToolIcon6} alt="icon" className="icon" />
                            <p className="text">{t("imp_txt6")}</p>
                        </div>
                    </div>
                </div>
                <div className="right gap-4">
                    <p className="sub-title">{t("serv")}</p>
                    <a href='unisoftprices.xlsx' download={'unisoft_prices.xlsx'} className="contact_btn big-text mtop-1 ver_1">{t("price_btn")}</a>
                    <div className="info gap-1-5">
                        <div className="range"></div>
                        <p className="big-text">{t("serv_title1")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt1_1")}</p>
                            <p className="text">{t("serv_txt1_2")}</p>
                        </div>
                    </div>
                    <div className="info gap-1-5">
                        <div className="range range_blue"></div>
                        <p className="big-text">{t("serv_title2")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt2_1")}</p>
                            <p className="text">{t("serv_txt2_2")}</p>
                        </div>
                    </div>
                    <div className="info gap-1-5">
                        <div className="range range_blue"></div>
                        <p className="big-text">{t("serv_title3")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt3_1")}</p>
                            <p className="text">{t("serv_txt3_2")}</p>
                            <p className="text">{t("serv_txt3_3")}</p>
                        </div>
                    </div>
                    <div className="info gap-1-5">
                        <div className="range"></div>
                        <p className="big-text">{t("serv_title4")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt4_1")}</p>
                            <p className="text">{t("serv_txt4_2")}</p>
                            <p className="text">{t("serv_txt4_3")}</p>
                        </div>
                    </div>
                    <div className="info gap-1-5">
                        <div className="range"></div>
                        <p className="big-text">{t("serv_title5")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt5_1")}</p>
                            <p className="text">{t("serv_txt5_2")}</p>
                            <p className="text">{t("serv_txt5_3")}</p>
                        </div>
                    </div>
                    <div className="info gap-1-5">
                        <div className="range range_blue"></div>
                        <p className="big-text">{t("serv_title6")}</p>
                        <div className="texts">
                            <p className="text">{t("serv_txt6_1")}</p>
                            <p className="text">{t("serv_txt6_2")}</p>
                        </div>
                    </div>
                    <a href='unisoftprices.xlsx' download={'unisoft_prices.xlsx'} className="contact_btn big-text mtop-1 ver_2">{t("price_btn")}</a>
                </div>
            </div>
        </div>
    )
}

export default Prices
