import 'swiper/css';
import 'swiper/css/scrollbar';
import '../../styles/home/Products.scss';
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Scrollbar } from 'swiper/modules';

function Products() {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="Products parent" id='products'>
            <div className="infos">
                <Swiper
                    slidesPerView={2}
                    scrollbar={{
                        hide: true,
                    }}
                    autoplay={{ delay: 2000 }}
                    modules={[Scrollbar, Autoplay]}
                    breakpoints={{
                        768: {
                            slidesPerView: 5
                        }
                    }}
                    className="mySwiper"
                >
                    <SwiperSlide className="name">
                        {t("products")}
                    </SwiperSlide>
                    <SwiperSlide className='info'>
                        <p className="text">{t("prod1")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod2")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod3")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod4")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod5")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod6")}</p>
                    </SwiperSlide>
                    <SwiperSlide className="info">
                        <p className="text">{t("prod7")}</p>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    )
}

export default Products
