import '../../styles/defaults/Modal.scss';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../assets/icons/times.svg';

type ModalProps = {
    changeShowModal: () => void
}

function Modal(props: ModalProps) {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="Modal">
            <div className="contrast-05" onClick={props.changeShowModal}></div>
            <div className="child round-1 pd-2-5 gap-1">
                <button className="close_btn pd-05 round-05 op-07" onClick={props.changeShowModal}>
                    <img src={CloseIcon} alt="icon" className="icon" />
                </button>
                <p className="name">{t("1c_dev")}</p>
                <p className="big-text mtop-1">{t("requests")}</p>
                <div className="texts gap-05">
                    <p className="text">{t("vac_txt1")}</p>
                    <p className="text">{t("vac_txt2")}</p>
                    <p className="text">{t("vac_txt3")}</p>
                    <p className="text">{t("vac_txt4")}</p>
                    <p className="text">{t("vac_txt5")}</p>
                    <p className="text">{t("vac_txt6")}</p>
                </div>
                <p className="big-text mtop-1">{t("conditions")}</p>
                <div className="texts gap-05">
                    <p className="text">{t("vac_txt7")}</p>
                    <p className="text">{t("vac_txt8")}</p>
                    <p className="text">{t("vac_txt9")}</p>
                    <p className="text">{t("vac_txt10")}</p>
                </div>
                <p className="name mtop-2">{t("1c_indev")}</p>
                <p className="big-text mtop-1">{t("requests")}</p>
                <div className="texts gap-05">
                    <p className="text">{t("vac_txt11")}</p>
                    <p className="text">{t("vac_txt12")}</p>
                    <p className="text">{t("vac_txt13")}</p>
                </div>
                <p className="big-text mtop-1">{t("conditions")}</p>
                <div className="texts">
                    <p className="text">{t("vac_txt7")}</p>
                    <p className="text">{t("vac_txt8")}</p>
                    <p className="text">{t("vac_txt9")}</p>
                    <p className="text">{t("vac_txt10")}</p>
                </div>
            </div>
        </div>
    )
}

export default Modal
