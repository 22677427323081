import '../../styles/home/OurClients.scss';
import { useTranslation } from 'react-i18next';
import EmailIcon from '../../assets/icons/email.png';
import ClientImg1 from '../../assets/images/client1.png';
import ClientImg2 from '../../assets/images/client2.png';
import ClientImg3 from '../../assets/images/client3.png';
import ClientImg4 from '../../assets/images/client4.png';
import ClientImg5 from '../../assets/images/client5.png';
import ClientImg6 from '../../assets/images/client6.png';
import ClientImg7 from '../../assets/images/client7.png';
import ClientImg8 from '../../assets/images/client8.png';
import ClientImg9 from '../../assets/images/client9.png';
import SupportsIcon from '../../assets/icons/support.png';
import ClientImg11 from '../../assets/images/client11.png';
import ClientImg13 from '../../assets/images/client13.png';
import ClientImg14 from '../../assets/images/client14.png';
import ClientImg15 from '../../assets/images/client15.png';
import TelegramIcon from '../../assets/icons/telegram.png';

function OurClients() {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="OurClients parent" id='clients'>
            <div className="wrapper">
                <div className="left gap-2">
                    <p className="big-text">{t("our_clients")}</p>
                    <a href='https://pepsicobottler.uz/' target='_blank' className="client">
                        <img src={ClientImg1} alt="img" className="img" />
                    </a>
                    <a href='https://akhancem.uz/' target='_blank' className="client">
                        <img src={ClientImg2} alt="img" className="img" />
                    </a>
                    <a href='https://www.agmk.uz/' target='_blank' className="client">
                        <img src={ClientImg3} alt="img" className="img" />
                    </a>
                </div>
                <div className="right gap-3" id='supports'>
                    <img src={SupportsIcon} alt="support" className="support" />
                    <a href='mailto:info@unisoft.uz' className="name gap-1">
                        <img src={EmailIcon} alt="icon" className="icon" />
                        info@unisoft.uz
                    </a>
                    <a href='https://t.me/@unisoft_info' target='_blank' className="name gap-1">
                        <img src={TelegramIcon} alt="icon" className="icon" />
                        @unisoft_info
                    </a>
                    <a href='https://t.me/@unisoft_uz' target='_blank' className="name">
                        @unisoft_uz
                    </a>
                </div>
            </div>
            <div className="wrapper" style={{ paddingTop: 0 }}>
                <div className="left gap-2">
                    <a href='https://bekabadcement.uz/' target='_blank' className="client">
                        <img src={ClientImg4} alt="img" className="img" />
                    </a>
                    <a href='https://beeline.uz/' target='_blank' className="client">
                        <img src={ClientImg5} alt="img" className="img" />
                    </a>
                    <a href='https://www.wiut.uz/' target='_blank' className="client">
                        <img src={ClientImg6} alt="img" className="img" />
                    </a>
                    <a href='https://uzinkass.uz/' target='_blank' className="client">
                        <img src={ClientImg7} alt="img" className="img" />
                    </a>
                    <a href='https://www.navoiyazot.uz/' target='_blank' className="client">
                        <img src={ClientImg8} alt="img" className="img" />
                    </a>
                </div>
                <div className="left gap-2">
                    <a href='https://kuvasaycement.uz/' target='_blank' className="client">
                        <img src={ClientImg9} alt="img" className="img" />
                    </a>                  
                    <a href='https://naturaljuice.uz/' target='_blank' className="client">
                        <img src={ClientImg11} alt="img" className="img" />
                    </a>
                    <a href='https://tashkentsteel.uz/uz/bosh-sahifa/' target='_blank' className="client">
                        <img src={ClientImg13} alt="img" className="img" />
                    </a>
                    <a href='https://www.uzbeksteel.uz/' target='_blank' className="client">
                        <img src={ClientImg14} alt="img" className="img" />
                    </a>
                    <a href='https://sherin.uz/' target='_blank' className="client">
                        <img src={ClientImg15} alt="img" className="img" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default OurClients
