import '../../styles/defaults/Modal.scss';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../assets/icons/times.svg';

type SuccessModalProps = {
    changeShowSuccessModal: () => void
}

function SuccessModal(props: SuccessModalProps) {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="Modal">
            <div className="contrast-05" onClick={props.changeShowSuccessModal}></div>
            <div className="child_2 round-1 pd-3 gap-1">
                <button className="close_btn pd-05 round-05 op-07" onClick={props.changeShowSuccessModal}>
                    <img src={CloseIcon} alt="icon" className="icon" />
                </button>
                <p className="name">{t("suc_mes")}</p>
            </div>
        </div>
    )
}

export default SuccessModal
