import { useState } from "react";
import Header from "../components/home/Header";
import Prices from "../components/home/Prices";
import Modal from "../components/defaults/Modal";
import Contact from "../components/home/Contact";
import Navbar from "../components/defaults/Navbar";
import Products from "../components/home/Products";
import Vacancies from "../components/home/Vacancies";
import OurClients from "../components/home/OurClients";
import SuccessModal from "../components/defaults/SuccessModal";
import About from "../components/home/About";

function HomeRoutes() {

    // modal options

    const [showModal, setShowModal] = useState<boolean>(false);

    function changeShowModal() {
        setShowModal(!showModal)
    };

    // success modal options

    const [showSuccessModal, setShowSuccessModal] = useState<boolean>(false);

    function changeShowSuccessModal() {
        setShowSuccessModal(!showSuccessModal)
    };

    return (
        <div>
            <Navbar />
            <Header />
            <Products />
            <About />
            <Prices />
            <Contact changeShowSuccessModal={changeShowSuccessModal} />
            <Vacancies changeShowModal={changeShowModal} />
            <OurClients />
            {showModal &&
                <Modal changeShowModal={changeShowModal} />
            }
            {showSuccessModal &&
                <SuccessModal changeShowSuccessModal={changeShowSuccessModal} />
            }
        </div>
    )
}

export default HomeRoutes
