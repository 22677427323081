import '../../styles/home/Header.scss';
import { useTranslation } from 'react-i18next';
import Img from '../../assets/images/header.webp';

function Header() {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="Header parent">
            <div className="wrapper">
                <div className="circle_1"></div>
                <div className="circle_2"></div>
                <img src={Img} alt="img" className="img" />
                <div className="texts gap-3 mtop-4">
                    <h1 className="title"><b>{t("head_txt")}</b> <span>UNISOFT</span></h1>
                    <a href='#contacts' className="contact_btn op-08 big-text">{t("submit_btn")}</a>
                </div>
            </div>
        </div>
    )
}

export default Header
