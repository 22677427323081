import '../../styles/home/About.scss';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Logo from '../../assets/images/logo1c.png';

function About() {

    // i18next

    const { t } = useTranslation();

    return (
        <div className="About parent">
            <div className="wrapper gap-2 ver-1">
                <Link to="/" className='logo'>
                    <img src={Logo} alt="logo" className="img" />
                </Link>
                <div className="texts gap-2">
                    <h1 className="sub-title">{t("about")}</h1>
                    <p className="big-text">{t("about_txt")}</p>
                </div>
            </div>
            <div className="wrapper gap-2 ver-2">
                <div className="top gap-2">
                    <Link to="/" className='logo'>
                        <img src={Logo} alt="logo" className="img" />
                    </Link>
                    <h1 className="sub-title">{t("about")}</h1>
                </div>
                <p className="big-text">{t("about_txt")}</p>
            </div>
        </div>
    )
}

export default About
