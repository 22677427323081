import i18next from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "ru",
        ns: ["default"],
        defaultNS: "default",
        debug: false,
        detection: {
            order: ["localStorage", "cookie", "htmlTag", "path", "subdomain"],
            caches: ["localStorage", "cookie"]
        },
        cache: "no-store",
        supportedLngs: ["ru", "uz"],
        interpolation: {
            escapeValue: false
        },

        backend: {
            loadPath: '/translations/{{lng}}/default.json'
        }
    })