import '../../styles/home/Vacancies.scss';
import { useTranslation } from 'react-i18next';

type VacanciesProps = {
    changeShowModal: () => void
}

function Vacancies(props: VacanciesProps) {

    // i18 next

    const { t } = useTranslation();

    return (
        <div className="Vacancies parent" id='vacancies'>
            <div className="wrapper gap-3">
                <p className="sub-title">{t("vac_title")}</p>
                <button className="contact_btn big-text" onClick={props.changeShowModal}>{t("vacancies")}</button>
            </div>
        </div>
    )
}

export default Vacancies
