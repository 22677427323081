import { Link } from "react-router-dom";
import '../../styles/defaults/Navbar.scss';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Logo from '../../assets/images/logo.png';
import MenuIcon from '../../assets/icons/menu.svg';
import CloseIcon from '../../assets/icons/times.svg';
import PhoneIcon from '../../assets/icons/phone.png';

function Navbar() {

    // menu options

    const [showMenu, setShowMenu] = useState<boolean>(false);

    // languages options

    const { i18n, t } = useTranslation();
    let lang = localStorage.getItem('i18nextLng');
    const [language, setLanguage] = useState<boolean>(false);

    function changeLangValue(item: string) {
        setLanguage(false);
        setShowMenu(false);
        i18n.changeLanguage(item);
    };

    // scroll options

    const [scroll, setScroll] = useState<boolean>(false);

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setShowMenu(false);
            setLanguage(false);
            setScroll(window.scrollY > 10 ? true : false);
        });
    }, []);

    return (
        <header className={`Navbar gap-3 ${scroll && "ScrollingNavbar"}`}>
            <Link to="/" className="logo" onClick={() => window.scrollTo(0, 0)}>
                <img src={Logo} alt="img" className="img" />
            </Link>
            <nav className={`links gap-2-5 ${showMenu && "show_links"}`}>
                <a href="#products" className="link text" onClick={() => setShowMenu(false)}>{t("nav1")}</a>
                <a href="#implementation" className="link text" onClick={() => setShowMenu(false)}>{t("nav2")}</a>
                <a href="#implementation" className="link text" onClick={() => setShowMenu(false)}>{t("nav3")}</a>
                <a href="#implementation" className="link text" onClick={() => setShowMenu(false)}>{t("nav4")}</a>
                <a href="#clients" className="link text" onClick={() => setShowMenu(false)}>{t("nav5")}</a>
                <a href="#vacancies" className="link text" onClick={() => setShowMenu(false)}>{t("nav6")}</a>
                <a href="#supports" className="link text" onClick={() => setShowMenu(false)}>{t("nav7")}</a>
                <div className="language">
                    <button className="lang_title round-05 text" onClick={() => setLanguage(true)}>{lang == "uz" ? "UZ" : "RU"}</button>
                    {language &&
                        <div className="lang_menu round-05">
                            <button className="lang text" onClick={() => changeLangValue("uz")}>UZ</button>
                            <button className="lang text" onClick={() => changeLangValue("ru")}>RU</button>
                        </div>
                    }
                    {language &&
                        <div className="contrast-0" onClick={() => setLanguage(false)}></div>
                    }
                </div>
                <button className="close_btn round-05 pd-05" onClick={() => setShowMenu(false)}>
                    <img src={CloseIcon} alt="icon" className="icon" />
                </button>
            </nav>
            <a href="tel:+998903157510" className="tel_btn gap-1 big-text scale-05">
                <img src={PhoneIcon} alt="icon" className="icon" />
                998 90 315 75 10
            </a>
            <button className="menu_btn round-05 pd-05" onClick={() => setShowMenu(true)}>
                <img src={MenuIcon} alt="icon" className="icon" />
            </button>
        </header>
    )
}

export default Navbar
